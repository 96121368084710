<template>
  <div style="text-align:center;margin-top: 200px;">

    <div>
      <img width="90" src="@/assets/logo.png" style="border-radius: 50%"  alt="">
      <h2>红了樱桃</h2>
    </div>

    <div>
      <br /> <br />
      <van-button type="success" size="small" @click="goWechat()">点击微信授权登录</van-button>
    </div>

    <van-overlay :show="loading">
      <div style="margin-top: 200px;text-align: center;">
        <van-loading type="spinner" /><span style="color:#fff;">登录中...</span>
      </div>
    </van-overlay>

  </div>
</template>


<script>
  import {apiLogin} from '@/api/authApi'
  import {loginUser} from '@/plugins/auth'
  export default {
      name: 'login',
      data () {
          return {
              code: '',
            loading: false,
          }
      },
      created () {
        this.isWeixin()
      },
      methods: {
        isWeixin () {
          if (this.isWeChat()) {
            this.init()
          } else {
            this.$router.push({
              name: 'wechat.error'
            })
          }
        },
        isWeChat(){
          //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
          let ua = window.navigator.userAgent.toLowerCase();
          //通过正则表达式匹配ua中是否含有MicroMessenger字符串
          if(ua.match(/MicroMessenger/i) == 'micromessenger'){
            return true;
          }else{
            return false;
          }
        },
        init() {
          this.code = this.$route.query.code

          if (this.code) {

            this.loading = true

            apiLogin({code: this.code}).then(data =>{
              loginUser(data)

              // 去重定向地址或主页
              if (this.$route.query.redirect_url) {
                window.location = this.$route.query.redirect_url
              } else {
                this.$router.push({name: 'index'})
              }
            }).catch(() =>{
              this.loading = false
            })
          }
        },
        goWechat () {
          let url = "http://cdn.hongleyingtao.cn/wx-auth.html?" +
              "appid=wx4f06f88892e2c1a9" +
              "&internal_redirect_url=" + encodeURIComponent(window.location.href) +
              "&response_type=code" +
              "&scope=snsapi_userinfo" +
              '&state=redirect'

          window.location = url
        },
      }
  }
</script>
